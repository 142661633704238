import { default as adhesionq8zoStPwwOMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/adhesion.vue?macro=true";
import { default as attrition_45clustersP9QTBAj1ERMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/attrition-clusters.vue?macro=true";
import { default as employee_45recommendation2f4P2Troi8Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue?macro=true";
import { default as hr_45recommendation186vzPoxa3Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue?macro=true";
import { default as leader_45recommendationoV75CS7fAtMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue?macro=true";
import { default as boosterhBdpxbykTYXMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/boosterh.vue?macro=true";
import { default as culture_45elementsJV8TgbtNZfMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/culture-elements.vue?macro=true";
import { default as culture_45typesCdbjSur1yPMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/culture-types.vue?macro=true";
import { default as culture_45valuesNjele8I6J5Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/culture-values.vue?macro=true";
import { default as customized_45questions7yvlXxZd2DMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/customized-questions.vue?macro=true";
import { default as dissonanceqhqywbqSzEMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/dissonance.vue?macro=true";
import { default as effectiveness0YTtSTAMJSMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/effectiveness.vue?macro=true";
import { default as engagement_45clusters9EatYTsXspMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/engagement-clusters.vue?macro=true";
import { default as engagement_45dimensionU2mVClRg0cMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/engagement-dimension.vue?macro=true";
import { default as enpsWz20d0PaZkMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/enps.vue?macro=true";
import { default as experience_45dimensionTWbZRMAXICMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/experience-dimension.vue?macro=true";
import { default as family_45dimensionHSAZA57JARMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/family-dimension.vue?macro=true";
import { default as flow1cb4hLPBcQMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/flow.vue?macro=true";
import { default as indexizhevcPPBzMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/index.vue?macro=true";
import { default as agentsZIZmiaOZZ6Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/influencers/agents.vue?macro=true";
import { default as expertskkHRRltuRVMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/influencers/experts.vue?macro=true";
import { default as mentorsUJ472FtDTRMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/influencers/mentors.vue?macro=true";
import { default as influencers6jBHsgIWp6Meta } from "/var/www/app.winx.ai/pages/[[campaign]]/influencers.vue?macro=true";
import { default as magicRQh6JfzuLcMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/magic.vue?macro=true";
import { default as highlightOzGIV4eH3sMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/open-questions/highlight.vue?macro=true";
import { default as indexqpkEBUiIMWMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/open-questions/index.vue?macro=true";
import { default as ranking_45by_45filter0xMN0wREmsMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue?macro=true";
import { default as ranking_45by_45questionKV37HyD3WzMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/ranking-by-question.vue?macro=true";
import { default as resumeeg7eboiV5TMeta } from "/var/www/app.winx.ai/pages/[[campaign]]/resume.vue?macro=true";
import { default as _91_91campaign_93_9375uCv6nucNMeta } from "/var/www/app.winx.ai/pages/[[campaign]].vue?macro=true";
import { default as indexaSbUcaqdZsMeta } from "/var/www/app.winx.ai/pages/index.vue?macro=true";
import { default as inside_45consultant_45employeegK46AEOxcVMeta } from "/var/www/app.winx.ai/pages/inside-consultant-employee.vue?macro=true";
import { default as indexIv5xjt9KyvMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/index.vue?macro=true";
import { default as key_45result_45createLXH2XwJsV4Meta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue?macro=true";
import { default as updatektyNzXavcBMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue?macro=true";
import { default as viewPd84qOt8gtMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue?macro=true";
import { default as updateofjgjY6yaYMeta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue?macro=true";
import { default as objectives_45creategjz6UQplu3Meta } from "/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue?macro=true";
import { default as plans_45createqH6HLn9Uo6Meta } from "/var/www/app.winx.ai/pages/okrs/plans-create.vue?macro=true";
import { default as okrsjD9yTnReogMeta } from "/var/www/app.winx.ai/pages/okrs.vue?macro=true";
import { default as loginz86OtDOe53Meta } from "/var/www/app.winx.ai/node_modules/winx-base/pages/login.vue?macro=true";
export default [
  {
    path: _91_91campaign_93_9375uCv6nucNMeta?.path ?? "/:campaign?",
    children: [
  {
    name: adhesionq8zoStPwwOMeta?.name ?? "campaign-adhesion",
    path: adhesionq8zoStPwwOMeta?.path ?? "adhesion",
    meta: adhesionq8zoStPwwOMeta || {},
    alias: adhesionq8zoStPwwOMeta?.alias || [],
    redirect: adhesionq8zoStPwwOMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/adhesion.vue").then(m => m.default || m)
  },
  {
    name: attrition_45clustersP9QTBAj1ERMeta?.name ?? "campaign-attrition-clusters",
    path: attrition_45clustersP9QTBAj1ERMeta?.path ?? "attrition-clusters",
    meta: attrition_45clustersP9QTBAj1ERMeta || {},
    alias: attrition_45clustersP9QTBAj1ERMeta?.alias || [],
    redirect: attrition_45clustersP9QTBAj1ERMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/attrition-clusters.vue").then(m => m.default || m)
  },
  {
    name: boosterhBdpxbykTYXMeta?.name ?? "campaign-boosterh",
    path: boosterhBdpxbykTYXMeta?.path ?? "boosterh",
    children: [
  {
    name: employee_45recommendation2f4P2Troi8Meta?.name ?? "campaign-boosterh-employee-recommendation",
    path: employee_45recommendation2f4P2Troi8Meta?.path ?? "employee-recommendation",
    meta: employee_45recommendation2f4P2Troi8Meta || {},
    alias: employee_45recommendation2f4P2Troi8Meta?.alias || [],
    redirect: employee_45recommendation2f4P2Troi8Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue").then(m => m.default || m)
  },
  {
    name: hr_45recommendation186vzPoxa3Meta?.name ?? "campaign-boosterh-hr-recommendation",
    path: hr_45recommendation186vzPoxa3Meta?.path ?? "hr-recommendation",
    meta: hr_45recommendation186vzPoxa3Meta || {},
    alias: hr_45recommendation186vzPoxa3Meta?.alias || [],
    redirect: hr_45recommendation186vzPoxa3Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue").then(m => m.default || m)
  },
  {
    name: leader_45recommendationoV75CS7fAtMeta?.name ?? "campaign-boosterh-leader-recommendation",
    path: leader_45recommendationoV75CS7fAtMeta?.path ?? "leader-recommendation",
    meta: leader_45recommendationoV75CS7fAtMeta || {},
    alias: leader_45recommendationoV75CS7fAtMeta?.alias || [],
    redirect: leader_45recommendationoV75CS7fAtMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue").then(m => m.default || m)
  }
],
    meta: boosterhBdpxbykTYXMeta || {},
    alias: boosterhBdpxbykTYXMeta?.alias || [],
    redirect: boosterhBdpxbykTYXMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/boosterh.vue").then(m => m.default || m)
  },
  {
    name: culture_45elementsJV8TgbtNZfMeta?.name ?? "campaign-culture-elements",
    path: culture_45elementsJV8TgbtNZfMeta?.path ?? "culture-elements",
    meta: culture_45elementsJV8TgbtNZfMeta || {},
    alias: culture_45elementsJV8TgbtNZfMeta?.alias || [],
    redirect: culture_45elementsJV8TgbtNZfMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/culture-elements.vue").then(m => m.default || m)
  },
  {
    name: culture_45typesCdbjSur1yPMeta?.name ?? "campaign-culture-types",
    path: culture_45typesCdbjSur1yPMeta?.path ?? "culture-types",
    meta: culture_45typesCdbjSur1yPMeta || {},
    alias: culture_45typesCdbjSur1yPMeta?.alias || [],
    redirect: culture_45typesCdbjSur1yPMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/culture-types.vue").then(m => m.default || m)
  },
  {
    name: culture_45valuesNjele8I6J5Meta?.name ?? "campaign-culture-values",
    path: culture_45valuesNjele8I6J5Meta?.path ?? "culture-values",
    meta: culture_45valuesNjele8I6J5Meta || {},
    alias: culture_45valuesNjele8I6J5Meta?.alias || [],
    redirect: culture_45valuesNjele8I6J5Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/culture-values.vue").then(m => m.default || m)
  },
  {
    name: customized_45questions7yvlXxZd2DMeta?.name ?? "campaign-customized-questions",
    path: customized_45questions7yvlXxZd2DMeta?.path ?? "customized-questions",
    meta: customized_45questions7yvlXxZd2DMeta || {},
    alias: customized_45questions7yvlXxZd2DMeta?.alias || [],
    redirect: customized_45questions7yvlXxZd2DMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/customized-questions.vue").then(m => m.default || m)
  },
  {
    name: dissonanceqhqywbqSzEMeta?.name ?? "campaign-dissonance",
    path: dissonanceqhqywbqSzEMeta?.path ?? "dissonance",
    meta: dissonanceqhqywbqSzEMeta || {},
    alias: dissonanceqhqywbqSzEMeta?.alias || [],
    redirect: dissonanceqhqywbqSzEMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/dissonance.vue").then(m => m.default || m)
  },
  {
    name: effectiveness0YTtSTAMJSMeta?.name ?? "campaign-effectiveness",
    path: effectiveness0YTtSTAMJSMeta?.path ?? "effectiveness",
    meta: effectiveness0YTtSTAMJSMeta || {},
    alias: effectiveness0YTtSTAMJSMeta?.alias || [],
    redirect: effectiveness0YTtSTAMJSMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/effectiveness.vue").then(m => m.default || m)
  },
  {
    name: engagement_45clusters9EatYTsXspMeta?.name ?? "campaign-engagement-clusters",
    path: engagement_45clusters9EatYTsXspMeta?.path ?? "engagement-clusters",
    meta: engagement_45clusters9EatYTsXspMeta || {},
    alias: engagement_45clusters9EatYTsXspMeta?.alias || [],
    redirect: engagement_45clusters9EatYTsXspMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/engagement-clusters.vue").then(m => m.default || m)
  },
  {
    name: engagement_45dimensionU2mVClRg0cMeta?.name ?? "campaign-engagement-dimension",
    path: engagement_45dimensionU2mVClRg0cMeta?.path ?? "engagement-dimension",
    meta: engagement_45dimensionU2mVClRg0cMeta || {},
    alias: engagement_45dimensionU2mVClRg0cMeta?.alias || [],
    redirect: engagement_45dimensionU2mVClRg0cMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/engagement-dimension.vue").then(m => m.default || m)
  },
  {
    name: enpsWz20d0PaZkMeta?.name ?? "campaign-enps",
    path: enpsWz20d0PaZkMeta?.path ?? "enps",
    meta: enpsWz20d0PaZkMeta || {},
    alias: enpsWz20d0PaZkMeta?.alias || [],
    redirect: enpsWz20d0PaZkMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/enps.vue").then(m => m.default || m)
  },
  {
    name: experience_45dimensionTWbZRMAXICMeta?.name ?? "campaign-experience-dimension",
    path: experience_45dimensionTWbZRMAXICMeta?.path ?? "experience-dimension",
    meta: experience_45dimensionTWbZRMAXICMeta || {},
    alias: experience_45dimensionTWbZRMAXICMeta?.alias || [],
    redirect: experience_45dimensionTWbZRMAXICMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/experience-dimension.vue").then(m => m.default || m)
  },
  {
    name: family_45dimensionHSAZA57JARMeta?.name ?? "campaign-family-dimension",
    path: family_45dimensionHSAZA57JARMeta?.path ?? "family-dimension",
    meta: family_45dimensionHSAZA57JARMeta || {},
    alias: family_45dimensionHSAZA57JARMeta?.alias || [],
    redirect: family_45dimensionHSAZA57JARMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/family-dimension.vue").then(m => m.default || m)
  },
  {
    name: flow1cb4hLPBcQMeta?.name ?? "campaign-flow",
    path: flow1cb4hLPBcQMeta?.path ?? "flow",
    meta: flow1cb4hLPBcQMeta || {},
    alias: flow1cb4hLPBcQMeta?.alias || [],
    redirect: flow1cb4hLPBcQMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/flow.vue").then(m => m.default || m)
  },
  {
    name: indexizhevcPPBzMeta?.name ?? "campaign",
    path: indexizhevcPPBzMeta?.path ?? "",
    meta: indexizhevcPPBzMeta || {},
    alias: indexizhevcPPBzMeta?.alias || [],
    redirect: indexizhevcPPBzMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/index.vue").then(m => m.default || m)
  },
  {
    name: influencers6jBHsgIWp6Meta?.name ?? "campaign-influencers",
    path: influencers6jBHsgIWp6Meta?.path ?? "influencers",
    children: [
  {
    name: agentsZIZmiaOZZ6Meta?.name ?? "campaign-influencers-agents",
    path: agentsZIZmiaOZZ6Meta?.path ?? "agents",
    meta: agentsZIZmiaOZZ6Meta || {},
    alias: agentsZIZmiaOZZ6Meta?.alias || [],
    redirect: agentsZIZmiaOZZ6Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/influencers/agents.vue").then(m => m.default || m)
  },
  {
    name: expertskkHRRltuRVMeta?.name ?? "campaign-influencers-experts",
    path: expertskkHRRltuRVMeta?.path ?? "experts",
    meta: expertskkHRRltuRVMeta || {},
    alias: expertskkHRRltuRVMeta?.alias || [],
    redirect: expertskkHRRltuRVMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/influencers/experts.vue").then(m => m.default || m)
  },
  {
    name: mentorsUJ472FtDTRMeta?.name ?? "campaign-influencers-mentors",
    path: mentorsUJ472FtDTRMeta?.path ?? "mentors",
    meta: mentorsUJ472FtDTRMeta || {},
    alias: mentorsUJ472FtDTRMeta?.alias || [],
    redirect: mentorsUJ472FtDTRMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/influencers/mentors.vue").then(m => m.default || m)
  }
],
    meta: influencers6jBHsgIWp6Meta || {},
    alias: influencers6jBHsgIWp6Meta?.alias || [],
    redirect: influencers6jBHsgIWp6Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/influencers.vue").then(m => m.default || m)
  },
  {
    name: magicRQh6JfzuLcMeta?.name ?? "campaign-magic",
    path: magicRQh6JfzuLcMeta?.path ?? "magic",
    meta: magicRQh6JfzuLcMeta || {},
    alias: magicRQh6JfzuLcMeta?.alias || [],
    redirect: magicRQh6JfzuLcMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/magic.vue").then(m => m.default || m)
  },
  {
    name: highlightOzGIV4eH3sMeta?.name ?? "campaign-open-questions-highlight",
    path: highlightOzGIV4eH3sMeta?.path ?? "open-questions/highlight",
    meta: highlightOzGIV4eH3sMeta || {},
    alias: highlightOzGIV4eH3sMeta?.alias || [],
    redirect: highlightOzGIV4eH3sMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/open-questions/highlight.vue").then(m => m.default || m)
  },
  {
    name: indexqpkEBUiIMWMeta?.name ?? "campaign-open-questions",
    path: indexqpkEBUiIMWMeta?.path ?? "open-questions",
    meta: indexqpkEBUiIMWMeta || {},
    alias: indexqpkEBUiIMWMeta?.alias || [],
    redirect: indexqpkEBUiIMWMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/open-questions/index.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45filter0xMN0wREmsMeta?.name ?? "campaign-ranking-by-filter",
    path: ranking_45by_45filter0xMN0wREmsMeta?.path ?? "ranking-by-filter",
    meta: ranking_45by_45filter0xMN0wREmsMeta || {},
    alias: ranking_45by_45filter0xMN0wREmsMeta?.alias || [],
    redirect: ranking_45by_45filter0xMN0wREmsMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue").then(m => m.default || m)
  },
  {
    name: ranking_45by_45questionKV37HyD3WzMeta?.name ?? "campaign-ranking-by-question",
    path: ranking_45by_45questionKV37HyD3WzMeta?.path ?? "ranking-by-question",
    meta: ranking_45by_45questionKV37HyD3WzMeta || {},
    alias: ranking_45by_45questionKV37HyD3WzMeta?.alias || [],
    redirect: ranking_45by_45questionKV37HyD3WzMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/ranking-by-question.vue").then(m => m.default || m)
  },
  {
    name: resumeeg7eboiV5TMeta?.name ?? "campaign-resume",
    path: resumeeg7eboiV5TMeta?.path ?? "resume",
    meta: resumeeg7eboiV5TMeta || {},
    alias: resumeeg7eboiV5TMeta?.alias || [],
    redirect: resumeeg7eboiV5TMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]]/resume.vue").then(m => m.default || m)
  }
],
    name: _91_91campaign_93_9375uCv6nucNMeta?.name ?? undefined,
    meta: _91_91campaign_93_9375uCv6nucNMeta || {},
    alias: _91_91campaign_93_9375uCv6nucNMeta?.alias || [],
    redirect: _91_91campaign_93_9375uCv6nucNMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/[[campaign]].vue").then(m => m.default || m)
  },
  {
    name: indexaSbUcaqdZsMeta?.name ?? "index",
    path: indexaSbUcaqdZsMeta?.path ?? "/",
    meta: indexaSbUcaqdZsMeta || {},
    alias: indexaSbUcaqdZsMeta?.alias || [],
    redirect: indexaSbUcaqdZsMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inside_45consultant_45employeegK46AEOxcVMeta?.name ?? "inside-consultant-employee",
    path: inside_45consultant_45employeegK46AEOxcVMeta?.path ?? "/inside-consultant-employee",
    meta: inside_45consultant_45employeegK46AEOxcVMeta || {},
    alias: inside_45consultant_45employeegK46AEOxcVMeta?.alias || [],
    redirect: inside_45consultant_45employeegK46AEOxcVMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/inside-consultant-employee.vue").then(m => m.default || m)
  },
  {
    name: okrsjD9yTnReogMeta?.name ?? "okrs",
    path: okrsjD9yTnReogMeta?.path ?? "/okrs",
    children: [
  {
    name: indexIv5xjt9KyvMeta?.name ?? "okrs-plans-plan",
    path: indexIv5xjt9KyvMeta?.path ?? "plans-:plan()",
    meta: indexIv5xjt9KyvMeta || {},
    alias: indexIv5xjt9KyvMeta?.alias || [],
    redirect: indexIv5xjt9KyvMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: key_45result_45createLXH2XwJsV4Meta?.name ?? "okrs-plans-plan-objectives-objective-key-result-create",
    path: key_45result_45createLXH2XwJsV4Meta?.path ?? "plans-:plan()/objectives-:objective()/key-result-create",
    meta: key_45result_45createLXH2XwJsV4Meta || {},
    alias: key_45result_45createLXH2XwJsV4Meta?.alias || [],
    redirect: key_45result_45createLXH2XwJsV4Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue").then(m => m.default || m)
  },
  {
    name: updatektyNzXavcBMeta?.name ?? "okrs-plans-plan-objectives-objective-key-results-keyResult-update",
    path: updatektyNzXavcBMeta?.path ?? "plans-:plan()/objectives-:objective()/key-results-:keyResult()/update",
    meta: updatektyNzXavcBMeta || {},
    alias: updatektyNzXavcBMeta?.alias || [],
    redirect: updatektyNzXavcBMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue").then(m => m.default || m)
  },
  {
    name: viewPd84qOt8gtMeta?.name ?? "okrs-plans-plan-objectives-objective-key-results-keyResult-view",
    path: viewPd84qOt8gtMeta?.path ?? "plans-:plan()/objectives-:objective()/key-results-:keyResult()/view",
    meta: viewPd84qOt8gtMeta || {},
    alias: viewPd84qOt8gtMeta?.alias || [],
    redirect: viewPd84qOt8gtMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue").then(m => m.default || m)
  },
  {
    name: updateofjgjY6yaYMeta?.name ?? "okrs-plans-plan-objectives-objective-update",
    path: updateofjgjY6yaYMeta?.path ?? "plans-:plan()/objectives-:objective()/update",
    meta: updateofjgjY6yaYMeta || {},
    alias: updateofjgjY6yaYMeta?.alias || [],
    redirect: updateofjgjY6yaYMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue").then(m => m.default || m)
  },
  {
    name: objectives_45creategjz6UQplu3Meta?.name ?? "okrs-plans-plan-objectives-create",
    path: objectives_45creategjz6UQplu3Meta?.path ?? "plans-:plan()/objectives-create",
    meta: objectives_45creategjz6UQplu3Meta || {},
    alias: objectives_45creategjz6UQplu3Meta?.alias || [],
    redirect: objectives_45creategjz6UQplu3Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue").then(m => m.default || m)
  },
  {
    name: plans_45createqH6HLn9Uo6Meta?.name ?? "okrs-plans-create",
    path: plans_45createqH6HLn9Uo6Meta?.path ?? "plans-create",
    meta: plans_45createqH6HLn9Uo6Meta || {},
    alias: plans_45createqH6HLn9Uo6Meta?.alias || [],
    redirect: plans_45createqH6HLn9Uo6Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs/plans-create.vue").then(m => m.default || m)
  }
],
    meta: okrsjD9yTnReogMeta || {},
    alias: okrsjD9yTnReogMeta?.alias || [],
    redirect: okrsjD9yTnReogMeta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/pages/okrs.vue").then(m => m.default || m)
  },
  {
    name: loginz86OtDOe53Meta?.name ?? "login",
    path: loginz86OtDOe53Meta?.path ?? "/login",
    meta: loginz86OtDOe53Meta || {},
    alias: loginz86OtDOe53Meta?.alias || [],
    redirect: loginz86OtDOe53Meta?.redirect || undefined,
    component: () => import("/var/www/app.winx.ai/node_modules/winx-base/pages/login.vue").then(m => m.default || m)
  }
]